import { TaskData } from 'bookitup-form-builder';
import SemanticForm, { FormCustomItem, FormStyleAttributes } from '../../Types/SemanticForm';
import { ajaxActions } from '../AjaxActions';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_REQUEST_SERVICE_URL;
const S3_CONNECT_URL = process.env.REACT_APP_CONNECT_SERVICE_URL;

export const fetchFormTemplates = () => ajaxActions.get(`${BASE_URL}/semanticform/templates`).then((resp) => resp);

export const generateFormIframe = (profileId: number, formId: string) =>
  ajaxActions.get(`${BASE_URL}/js/${profileId}/form/${formId}`).then((resp) => resp);

export const generateFormScript = (profileId: number, formId: string, includeStyles?: boolean) =>
  ajaxActions
    .get(`${BASE_URL}/js/${profileId}/form/${formId}/js${includeStyles ? '?includeStyles=true' : ''}`)
    .then((resp) => resp);

export const createSemanticFormIframeUrl = (profileId: number, formId: string) =>
  `${S3_CONNECT_URL}/bookitup-forms/${profileId}/html/bookitup-form-${formId}.html`;

export const createSemanticFormStylesUrl = (profileId: number, formId: string) =>
  `${S3_CONNECT_URL}/bookitup-forms/${profileId}/css/bookitup-form-${formId}.css`;

export const createSemanticFormScriptUrl = (profileId: number, formId: string) =>
  `${S3_CONNECT_URL}/bookitup-forms/${profileId}/js/bookitup-script-${formId}-min.js`;

export const jsEmbeddingCodeSnippet = `
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v6.2.1/css/all.css">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css">
  <link rel="stylesheet" type="text/css" href="https://unpkg.com/bookitup-form-builder@1.1.3/dist/app.css">
  ~!~CUSTOM_STYLES~!~
    <div class="form-container">
      <div class="form-background"></div>
      <div id="form-generator" class="form-horizontal"></div>
    </div>

  <script src="https://unpkg.com/react@16.8.6/umd/react.production.min.js" crossorigin></script>
  <script src="https://unpkg.com/react-dom@16.8.6/umd/react-dom.production.min.js" crossorigin></script>
  <script src="https://unpkg.com/bookitup-form-builder@1.1.3/dist/app.js"></script>
  <script src="~!~CUSTOM_SCRIPT~!~"></script>
  `;

export const iFrameEmbeddingCodeSnippet = `
  <script src="https://cdn.jsdelivr.net/npm/iframe-resizer@4.3.6/js/iframeResizer.min.js"></script>
  <iframe id="formIframe" style="width: 1px;  min-width: 100%; border: none;" src="~!~CUSTOM_IFRAME_URL~!~"></iframe>
  <script>var f=document.getElementById("formIframe");f.addEventListener("load",function(){iFrameResize({heightCalculationMethod:"bodyScroll"},"#formIframe")});</script>
  `;

const create = async (form: Partial<SemanticForm>, templateId?: string): Promise<SemanticForm | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/semanticform${templateId ? `?templateId=${templateId}` : ''}`, form);
  if (res.ok) {
    ToastS.success('create-form', 'Formular erfolgreich erstellt.');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const getForms = async (): Promise<SemanticForm[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/semanticform`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const copy = async (originFormId: string): Promise<SemanticForm | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/semanticform/copy/${originFormId}`);
  if (res.status === 201) {
    ToastS.success('copy-created', 'Kopie erfolgreich erstellt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const getForm = async (formId: string): Promise<SemanticForm | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/semanticform/${formId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const patch = async (formId: string, form: Partial<SemanticForm>): Promise<SemanticForm | null> => {
  precheckForm(form);
  const res = await ajaxActions.patch(`${BASE_URL}/semanticform/${formId}`, form);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const precheckForm = (form: Partial<SemanticForm>) => {
  if (form.task_data) {
    const elementsIds = form.task_data.map((e) => e.id);
    // Remove all items with non-existing parent
    const removeOrphanItems = (data: any[]) => data.filter((i) => !(i.parentId && !elementsIds.includes(i.parentId)));
    // Remove children from container elements, which are no longer exists
    const removeNonExistingChildren = (data: any[]) => {
      data.forEach((element) => {
        // Check for container elements
        if (element.childItems) {
          element.childItems = element.childItems.filter((elementId: string) => elementsIds.includes(elementId));
        }
      });
      return data;
    };
    form.task_data = removeOrphanItems(form.task_data);
    form.task_data = removeNonExistingChildren(form.task_data);
  }
};

const patchStyles = async (formId: string, styles: Partial<FormStyleAttributes>): Promise<boolean> => {
  const res = await ajaxActions.patch(`${BASE_URL}/semanticform/${formId}/styles`, styles);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const deleteForm = async (formId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/semanticform/${formId}`).then((resp) => resp);
  if (res.ok) {
    ToastS.info('delete-form', 'Formular wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const syncFormFiles = async (formId: string): Promise<boolean> => {
  const res = await ajaxActions.get(`${BASE_URL}/js/form/${formId}/sync`);
  if (res.ok) {
    return true;
  }
  ToastS.error('form-preview-err', 'Die Vorschau kann derzeit nicht aktualisiert werden.');
  return false;
};

const generateIframeCode = (formId: string, profileId: number) => {
  const iframeUrl = createSemanticFormIframeUrl(profileId, formId);
  return iFrameEmbeddingCodeSnippet.replace('~!~CUSTOM_IFRAME_URL~!~', iframeUrl);
};

const generateJsCode = (formId: string, profileId: number) => {
  const stylesUrl = SemanticFormS.createSemanticFormStylesUrl(profileId, formId);
  const scriptUrl = SemanticFormS.createSemanticFormScriptUrl(profileId, formId);
  const stylesContent = `<link rel="stylesheet" type="text/css" href="${stylesUrl}">\n`;
  return jsEmbeddingCodeSnippet.replace('~!~CUSTOM_SCRIPT~!~', scriptUrl).replace('~!~CUSTOM_STYLES~!~', stylesContent);
};

const addCustomItem = async (formId: string, customItem: FormCustomItem): Promise<SemanticForm | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/semanticform/${formId}/item?customItem=${customItem}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const previewAvailable = (form: SemanticForm | null): boolean => {
  if (form === null) {
    return false;
  }
  const { task_data } = form;
  if (task_data.length === 0) {
    return false;
  }

  for (let i = 0; i < task_data.length; i++) {
    const item: any = task_data[i];
    // Check if all columnRows have at least one element defined
    if (item.element.includes('ColumnRow') && (!item.childItems || item.childItems.every((e: unknown) => e === null))) {
      return false;
    }
  }
  return true;
};

const emailPresent = (task_data: TaskData[]): boolean => {
  if (task_data.length === 0) {
    return false;
  }
  return (
    task_data.find((item: any) => item.field_name && item.field_name.startsWith('customer.emailAddress')) !== undefined
  );
};

const datePresent = (task_data: TaskData[]): boolean => {
  if (task_data.length === 0) {
    return false;
  }
  return task_data.find((item: any) => item.field_name && item.field_name.startsWith('date')) !== undefined;
};

export const SemanticFormS = {
  create,
  copy,
  getForm,
  getForms,
  patch,
  patchStyles,
  deleteForm,
  createSemanticFormScriptUrl,
  createSemanticFormIframeUrl,
  createSemanticFormStylesUrl,
  syncFormFiles,
  generateIframeCode,
  generateJsCode,
  addCustomItem,
  previewAvailable,
  emailPresent,
  datePresent,
};
